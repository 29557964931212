import React, { useEffect, useState } from "react";
import { LocalizedLink, TabsBox } from "../commonComponents";
const TabSectionBlockWithFlag = ({contentTabsSection}) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    let tabs = []
    contentTabsSection?.relationships?.fieldTabSectionItem?.forEach((tab, index) => {
      tabs.push(tab?.title)
    })
    setTabs(tabs)
  },[])
  return (
    <section>
      {!!tabs && <TabsBox
        title={"Flexible Options"}
        desc={"Meet your organization’s security and scalability needs with multiple deployment and licensing options"}
        tabs={tabs}
      >
        {contentTabsSection?.relationships?.fieldTabSectionItem?.map((tab, index) => {
          return tab?.relationships?.fieldTabItem?.map((tabItem, indexItem) => {
            return(
              <div>
                <h3 className="title-4">{tabItem?.title}</h3>
                <div className="columns mb-4">
                  <div className="column is-4-desktop is-12-mobile">
                    <ul>
                      {tabItem?.fieldKeyFeaturesBullets.map((bullets) => {
                        return(<li>{bullets}</li>)
                      })}
                    </ul>
                  </div>
                  <div className="column is-5-desktop is-12-mobile">
                    <p>
                      <div
                      dangerouslySetInnerHTML={{
                        __html: tabItem?.body?.value,
                      }}
                    />
                    </p>
                  </div>
                  <div className="column is-3-desktop is-12-mobile request-price">
                    <LocalizedLink to={tabItem?.fieldCtaUrl?.url} className="btn btn-primary">
                      {tabItem?.fieldCtaUrl?.title}
                    </LocalizedLink>
                  </div>
                </div>
                {indexItem !== tab?.relationships?.fieldTabItem.length-1 && <hr />}
              </div>
            )
          })
       
        })}
        
      </TabsBox>}
    </section>
  )
}
export default TabSectionBlockWithFlag